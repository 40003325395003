import api from "@/axios";

export default class LeadService {



    downloadBoleto(pedido){
        return api.post('api/sspedidobol', { responseType: 'blob', pedido}).then((res) => res.data);
    }


    downloadRelatComiss(pedido){
        return api.post('api/protrelatcomiss', { responseType: 'blob', pedido}).then((res) => res.data);
    }

    downloadRelatPedAberto(pedido){
        return api.post('api/protrelatpedaberto', { responseType: 'blob', pedido}).then((res) => res.data);
    }

    downloadRelatRegiao(pedido){
        return api.post('api/protrelatregiao', { responseType: 'blob', pedido}).then((res) => res.data);
    }


    downloadRelatNovosCli(pedido){
        return api.post('api/protrelatnovoscli', { responseType: 'blob', pedido}).then((res) => res.data);
    }


    baixarRelatCurvaAbc(pedido){
        return api.post('api/protrelatcurvaabc', { responseType: 'blob', pedido}).then((res) => res.data);
    }


    downloadRelatMeta(pedido){
        return api.post('api/protrelatmeta', { responseType: 'blob', pedido}).then((res) => res.data);
    }

    downloadRelatInadim(pedido){
        return api.post('api/protrelatinad', { responseType: 'blob', pedido}).then((res) => res.data);
    }

    downloadRelat798(pedido){
        return api.post('api/protrelat798', { responseType: 'blob', pedido}).then((res) => res.data);
    }


    // downloadAnalResult(pedido){
    //     return api.post('api/protanalresult', { responseType: 'blob', pedido}).then((res) => res.data);
    // }

    downloadRelat799(pedido){
        return api.post('api/protrelat799', { responseType: 'blob', pedido}).then((res) => res.data);
    }

    getssuserlog() {
        return api.get('api/ssusuariolog' ).then((res) => res.data);
    }

    getPesqCidade(params){
        // console.log(baseURLPython)
        return api.get('api/sspesqcid', {params}).then((res) => res.data);
    }


    getPedidosEmAberto() {
        return api.get('api/sspedidoabrt').then((res) => res.data);
    }

    getCarteiraClientes(cidade) {
        const params = cidade
        return api.get('api/sscarteiracli', {params}).then((res) => res.data);
    }

    getPedidosPediodo(periodo) {
        const params = periodo
        return api.get('api/sspedidoperi', {params}).then((res) => res.data);
    }

    getNotasPeriodo(periodo) {
        const params = periodo
        return api.get('api/ssnotasperi', {params}).then((res) => res.data);
    }

    getPedidosCliente(codcli) {
        const params = { codcli }
        return api.get('api/sspedidocli', {params}).then((res) => res.data);
    }


    getPedidoNovo() {
        return api.get('api/sspedidonovoget' ).then((res) => res.data);
    }

    getPedidos(params) {
        return api.get('api/sspedidoindex', { params}).then((res) => res.data);
    }


    getPedidosPeriodo(periodo) {
        const params = {periodo}
        return api.get('api/sspedidoindex', { params } ).then((res) => res.data);
    }

    getPedido(id) {
        const params = { id }
        return api.get('api/sspedidoget', {params}).then((res) => res.data);
    }

    getPedItem(id) {
        const params = { id }
        return api.get('api/sspeditemget', {params}).then((res) => res.data);
    }

    getPedTipoCod(cod) {
        const params = { cod }
        return api.get('api/sspedtipogetcod', {params}).then((res) => res.data);
    }

    getCondPgtoCod(cod) {
        const params = { cod }
        return api.get('api/sscondpgtogetcod', {params}).then((res) => res.data);
    }

    getTabPrecoCod(cod) {
        const params = { cod }
        return api.get('api/sstabprecogetcod', {params}).then((res) => res.data);
    }

    confirmarPed(pedido){
        return api.post('api/sspedidoconf', {pedido}).then((res) => res.data);
    }

    excluirPed(pedido){
        return api.post('api/sspedidoexclu', {pedido}).then((res) => res.data);
    }

    copiarPed(pedido){
        return api.post('api/sspedidocopy', {pedido}).then((res) => res.data);
    }

    downloadNota(pedido){
        return api.post('api/sspedidonota', { responseType: 'blob', pedido}).then((res) => res.data);
    }

    downloadXML(pedido){
        return api.post('api/sspedidoxml', { responseType: 'blob', pedido}).then((res) => res.data);
    }

    getPedPDF(id) {
        // return api.get('api/ssorctopdf/' + id ).then((res) => res.data);

        return api({
            url: api.getUri() + 'api/sspedpdf',
            method: 'GET',
            responseType: 'blob',
            params: {id}
        }).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'Pedido Venda Nesher N' + id + '.pdf');
            document.body.appendChild(fileLink);
            fileLink.click();
        });
    }

    downloadAnalResult(pedido) {
        // return api.get('api/ssorctopdf/' + id ).then((res) => res.data);

        try{


            return api({
                url: api.getUri() + 'api/protanalresult',
                method: 'GET',
                responseType: 'blob',
                params: pedido
            }).then((response) => {
                console.log('downloadAnalResult')
                console.log(response)
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'Analise Resultado.pdf');
                document.body.appendChild(fileLink);
                fileLink.click();
            });
        } catch (e) {
            console.log('erro api')
            console.log(e)
            return 'erro'
        }


        // return api({
        //     url: api.getUri() + 'api/sspedpdf',
        //     method: 'GET',
        //     responseType: 'blob',
        //     params: {id}
        // }).then((response) => {
        //     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        //     var fileLink = document.createElement('a');
        //     fileLink.href = fileURL;
        //     fileLink.setAttribute('download', 'Orcamento Nesher ' + id + '.pdf');
        //     document.body.appendChild(fileLink);
        //     fileLink.click();
        // });
    }

    downloadAnalResult2(pedido) {
        // return api.get('api/ssorctopdf/' + id ).then((res) => res.data);

        return api({
            url: api.getUri() + 'api/protanalresult2',
            method: 'GET',
            responseType: 'blob',
            params: pedido
        }).then((response) => {
            console.log('downloadAnalResult')
            console.log(response)
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', 'Analise Resultado2.pdf');
            document.body.appendChild(fileLink);
            fileLink.click();
        });

        // return api({
        //     url: api.getUri() + 'api/sspedpdf',
        //     method: 'GET',
        //     responseType: 'blob',
        //     params: {id}
        // }).then((response) => {
        //     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        //     var fileLink = document.createElement('a');
        //     fileLink.href = fileURL;
        //     fileLink.setAttribute('download', 'Orcamento Nesher ' + id + '.pdf');
        //     document.body.appendChild(fileLink);
        //     fileLink.click();
        // });
    }


    deletePedItem(id) {
        const params = { id }
        return api.get('api/sspeditemdel', {params}).then((res) => res.data);
    }

    savePedido(pedido) {
        return api.post('api/sspedidosave', {pedido} ).then((res) => res.data);
    }

    savePedItem(itemeped) {
        return api.post('api/sspeditemsave', {itemeped} ).then((res) => res.data);
    }


    getPesqCliente(params){
        return api.get('api/sspesqcli', {params}).then((res) => res.data);
    }

    getPesqCondicao(params){
        return api.get('api/sspesqcond', {params}).then((res) => res.data);
    }

    getPesqForma(params){
        return api.get('api/sspesqforma', {params}).then((res) => res.data);
    }

    getPesqPedTipo(params){
        return api.get('api/sspesqpedtipo', {params}).then((res) => res.data);
    }

    getPesqPedItem(params){
        return api.get('api/sspesqpeditem', {params}).then((res) => res.data);
    }

    getPesqTransp(params){
        return api.get('api/sspesqtransp', {params}).then((res) => res.data);
    }

    getPesqCodTab(params){
        return api.get('api/sspesqcodtab', {params}).then((res) => res.data);
    }

    getPesqRepres(params){
        return api.get('api/sspesqrepres', {params}).then((res) => res.data);
    }
}
